
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























.flexible-highlighted__content {
  @include mq(m) {
    padding-left: col(3, 10);
  }

  @include mq(l) {
    padding-left: col(4, 10);
  }
}

.title {
  max-width: 20em;

  .cpt-quote + .cpt-highlighted & {
    @include default-margin;
  }
}
